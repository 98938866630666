export const environment = {
  production: true,
  apiEndpoint: 'https://ms-resource.meerkat-spirit.com/',
  urlAdmin: 'https://front-office-admin.meerkat-spirit.com/',
  keycloak: {
    url: 'https://keycloak.meerkat-spirit.com',
    realm: 'meerkat',
    clientId: 'front' 
  },
  webSocketUrl: 'https://ms-resource.meerkat-spirit.com/resource-ws'
};
