import { Injectable } from '@angular/core';
import { RxStompService } from '@stomp/ng2-stompjs';
import { RxStompState } from '@stomp/rx-stomp';
import { distinctUntilChanged, filter, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as SockJS from 'sockjs-client';
import { IMessage } from '@stomp/stompjs';
import { Subject } from 'rxjs';


@Injectable({ providedIn: 'root' })
export class WebsocketService {
  public dataChannel$: Subject<any> = new Subject<any>();
  private ondestroy$ = new Subject();
  private readonly socket;

  constructor(private rxStompService: RxStompService) {
    this.socket = new SockJS(environment.webSocketUrl);
    this.connect();
  }

  private disconnect(): void {
    this.ondestroy$.next();
    this.rxStompService.deactivate();
  }

  private configureStomp() {

    this.rxStompService.configure({
      webSocketFactory: () => this.socket,
      // if you want to send token
      // connectHeaders: {
      // 'X-Authorization': `Bearer ${this.authService.storedToken}`,
      // },
      heartbeatIncoming: 20000,
      heartbeatOutgoing: 5000,
      reconnectDelay: 10,
      debug: (msg: string): void => {
        console.log(new Date(), msg);
      },
    });
  }

   connect(): void {
    this.configureStomp();

    this.rxStompService.connectionState$
      .pipe(
        distinctUntilChanged(),
        filter((state: RxStompState) => state === RxStompState.OPEN)
      )
      .subscribe(() => {
        this.rxStompService
          .watch('/meerkat/data')
          .pipe(map((message: IMessage) => JSON.parse(message.body)))
          .subscribe((message) => {
            console.log(message);
            this.dataChannel$.next(message);
          });
      });

    this.rxStompService.activate();
  }


}