import { Component, OnInit } from '@angular/core';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { GyTreeViewService } from '../gy-tree-view/gy-tree-view.service';
import { Router } from '@angular/router';
import { Data } from '../data/data.service';
import { FileNode } from '../gy-tree-view/file-node.model';

@Component({
  selector: 'app-tree-with-tooltips',
  templateUrl: './tree-with-tooltips.component.html',
  styleUrls: ['./tree-with-tooltips.component.scss'],
})
export class TreeWithTooltipsComponent {
  treeControl = new NestedTreeControl<FileNode>(node => node.children);
  dataSource = new MatTreeNestedDataSource<FileNode>();

  constructor(private gytreeviewService: GyTreeViewService,
    private router: Router,
    protected data: Data) 
  {
    this.loadAll();
  }
  hasChild = (_: number, node: FileNode) => !!node.children && node.children.length > 0;

  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
  }

  loadAll() {
    this.gytreeviewService.getTreeview();
    this.gytreeviewService.dataChange.subscribe((data) => {
      this.dataSource.data = data;
    });
  }

  filterChanged(filterText: string) {
    this.gytreeviewService.filter(filterText);
    if (filterText) {
      this.treeControl.expandAll();
    } else {
      this.treeControl.collapseAll();
    }
  }
}