import { files } from './example-data';
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { FileNode } from './file-node.model';
import { ConfigService } from 'src/app/core/services/config.service';
import { catchError } from 'rxjs/operators';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

@Injectable({ providedIn: 'root' })
export class GyTreeViewService {
  private apiUrl = 'api/treeviews';

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.apiUrl = this.configService.getConfig('apiEndpoint') + this.apiUrl;
  }

  getTreeview(req?: any) {
    //    this.initialize(files);
    return this.http
      .get<FileNode[]>(this.apiUrl, { observe: 'response' })
      .pipe(
        catchError((err) => {
          throw err;
        })
      )
      .subscribe((res: HttpResponse<FileNode[]>) => {
        this.initialize(res.body);
      });
  }

  dataChange = new BehaviorSubject<FileNode[]>([]);
  treeData: any[];
  //get data(): FileNode[] {
  //  return this.dataChange.value;
  //}

  initialize(items: FileNode[]) {
    this.treeData = items;
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    //     file node as children.
    const data = this.filterNode(items, '');
    // Notify the change.
    this.dataChange.next(data);
    // Notify the change.
    // return data;
  }

  public filter(filterText: string) {
    // Build the tree nodes from Json object. The result is a list of `TodoItemNode` with nested
    // file node as children.
    const data = this.filterNode(this.treeData, filterText);
    // Notify the change.
    this.dataChange.next(data);
  }

  public filterNode(items: any[], term: string): any[] {
    return items.reduce((acc, item) => {
      if (this.contains(item.name, term)) {
        acc.push(item);
      } else if (item.children && item.children.length > 0) {
        let newItems = this.filterNode(item.children, term);

        if (newItems.length > 0) {
          acc.push({ name: item.name, type: item.type, children: newItems });
        }
      }

      return acc;
    }, []);
  }

  public contains(text: string, term: string): boolean {
    return text.toLowerCase().indexOf(term.toLowerCase()) >= 0;
  }
}
