<!-- <mat-form-field>
  <input
    matInput
    autocomplete="off"
    placeholder="Search"
    (input)="filterChanged($event.target.value)"
  />
</mat-form-field> -->
<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="example-tree">
  <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
    <button mat-icon-button disabled></button>
    <mat-icon *ngIf="node.type === 'info'" class="tree-icon" [attr.aria-label]="node.type + 'icon'">{{ node.icon }}</mat-icon>
    <span matTooltip="{{ node.name }}" routerLinkActive="active-link" matLine [routerLink]="['/dashboard', node.collectionName]" class="tree-node">{{ node.name | truncate: 10 }}</span>
  </mat-tree-node>
  <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
    <div class="tree-node">
      <button
        mat-icon-button
        [attr.aria-label]="'toggle ' + node.name"
        matTreeNodeToggle
        [matTreeNodeToggleRecursive]="false"
      >
        <mat-icon class="mat-icon-rtl-mirror">
          {{ treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <mat-icon class="tree-icon">{{ node.icon }}</mat-icon>
      <span matTooltip="{{ node.name }}">{{ node.name | truncate: 10 }}</span>
    </div>
    <div [class.tree-invisible]="!treeControl.isExpanded(node)" class="nested-node">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>