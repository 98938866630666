import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as d3 from 'd3';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { WebsocketService } from 'src/app/core/services/websocket.service';
import { Pie } from '../timeline/timeline.model';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.scss']
})
export class PieComponent implements OnInit, OnChanges{
  @Input() public piechart;
  @Input() private today: any;
  private svg;
  private margin = 5;
  private width = 300;
  private height = 300;
  // The radius of the pie chart is half the smallest side
  private radius = Math.min(this.width, this.height) / 2 - this.margin;
  private colors;
  private data;
  subscription?: Subscription;
  constructor(private websocketService: WebsocketService) { }

  ngOnInit(): void {
    this.data = this.piechart.data;
    this.createSvg();
    this.createColors();
    this.drawChart();
    
    this.websocketService.dataChannel$
     .pipe(filter(item => (item => item instanceof Pie) && (item.collectionName === this.piechart.collectionName) && (this.isToday(this.today))))
     .subscribe(item => {
      console.log("Pie------->"+ item);
       if(this.data[objIndex] ==! undefined && this.data[objIndex].value ==! undefined){
        var objIndex = this.data.findIndex((obj => obj.color === item.pieData[0].color));
        this.data[objIndex].value = this.data[objIndex].value + item.pieData[0].value;
        this.data[objIndex].name = this.data[objIndex].value + '%';
        console.log('Apres name:'+ this.data[objIndex].name);
        d3.select(".piesvg").remove();
        this.createSvg();
        this.createColors();
        this.drawChart();
       }

     });
  }

  ngOnChanges(changes: SimpleChanges) {
    // Draw for the first time to initialize.
    console.log(changes);
   // this.generateData()
    if(changes.piechart !== undefined && !changes.piechart.firstChange){
      this.data= changes.piechart.currentValue.data;
      d3.select(".piesvg").remove();
      this.createSvg();
      this.createColors();
      this.drawChart();
    }
  }

  private createSvg(): void {
    this.svg = d3.select("#pie")
    .append("svg")
    .attr('class', 'piesvg')
    .attr("width", this.width)
    .attr("height", this.height)
    .append("g")
    .attr(
      "transform",
      "translate(" + this.width / 2 + "," + this.height / 2 + ")"
    );
  }

  private createColors(): void {
    this.colors = d3.scaleOrdinal()
    .range(this.data.map(d => d.color));
  }

  private drawChart(): void {
    // Compute the position of each group on the pie:
    const pie = d3.pie<any>().value((d: any) => Number(d.value));

    // Build the pie chart
    this.svg
    .selectAll('pieces')
    .data(pie(this.data))
    .enter()
    .append('path')
    .attr('d', d3.arc()
      .innerRadius(0)
      .outerRadius(this.radius)
    )
    .attr('fill', (d, i) => (this.colors(i)))
    .attr("stroke", "#121926")
    .style("stroke-width", "1px");

    // Add labels
    const labelLocation = d3.arc()
    .innerRadius(100)
    .outerRadius(this.radius);

    this.svg
    .selectAll('pieces')
    .data(pie(this.data))
    .enter()
    .append('text')
    .text(d => d.data.name)
    .attr("transform", d => "translate(" + labelLocation.centroid(d) + ")")
    .style("text-anchor", "middle")
    .style("font-size", 15);
  }

  private isToday(date): boolean{
    const today = new Date();
    const someDate = new Date(date);
    const result= someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear();
      return result;
  }
  
}
