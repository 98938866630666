import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { KeycloakService } from "keycloak-angular";

export function initializeKeycloak(keycloak: KeycloakService, location: Location) {
 return () =>
   keycloak.init({
     config: environment.keycloak,
     initOptions: {
       onLoad: 'check-sso',
       silentCheckSsoRedirectUri: window.location.origin + '/assets/silent-check-sso.html',
       enableLogging: true,
       checkLoginIframe : true   
     },
     loadUserProfileAtStartUp: true
   });
}