import { Injectable } from "@angular/core";
import * as d3 from "d3";

@Injectable({
  providedIn: "root"
})
export class D3Service {
  public d3 = d3;
  constructor() {}

  /**
   * Generates random letter string with specified length
   * @param length: number
   */
  public generateId(length): string {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
}
