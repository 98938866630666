import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UserInfoStore } from 'src/app/core/user/user-info.store';
import { UserInfo } from 'src/app/core/user/user-info';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  userInfoOidc$: Observable<UserInfo>;
  environment = environment;
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();
  userDetails: any;
  constructor(
    public keycloakService: KeycloakService,
    private userInfoStore: UserInfoStore
  ) {}

  ngOnInit() {

    this.keycloakService.loadUserProfile().then(profile => {
      console.log(profile.username);
      console.log(profile.email);
      console.log(profile); //gives you array of all attributes of user, extract what you need
    })
      this.userInfoOidc$ = this.userInfoStore.getUserInfo$();
  }

  signIn() {
    this.keycloakService.login();
  }

  async signOut() {
    await this.keycloakService.logout();
  }

  toggleSideBar() {
    this.toggleSideBarForMe.emit();
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 300);
  }


  openNewTab(){
    window.open(this.environment.urlAdmin);
  }
}
