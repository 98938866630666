import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ConfigService } from 'src/app/core/services/config.service';

@Injectable()
export class TimelineService {
  private apiUrl = 'api/timelines';

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.apiUrl = this.configService.getConfig('apiEndpoint') + this.apiUrl;
  }

  getTimelines(req: any) {
    let url = `${this.apiUrl}?collectionName=${req.collectionName}`;
    return this.http.get(url);
  }

  findByDates(req: any) {
    let url = `${this.apiUrl}?collectionName=${req.collectionName}&fromDate=${req.fromDate}&toDate=${req.toDate}`;
    return this.http.get(url);
  }
}
