import {
  Component,
  OnInit,
  OnChanges,
  Input,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import * as d3 from 'd3';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { WebsocketService } from 'src/app/core/services/websocket.service';
import { MessageWs } from './timeline.model';

@Component({
  selector: 'timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.css'],
})
export class TimelineComponent implements OnInit, OnDestroy, OnChanges {
  resizeObservable$: Observable<Event>
  resizeSubscription$: Subscription

  @Input() private chartData: any;
  @Input() public collectionName: string;
  @Input() public chartTitle: string;
  @Input() private today: any;

  private maxSeconds = 86000;
  private pixelsPerSecond = 2;
  private svgWidth;
  private svgHeight = 350;
  private border=true;
  private meanMs = 1000; // milliseconds
  private dev = 150;


  private margin = { top: 20, bottom: 20, left: 50, right: 30, topNav: 10, bottomNav: 20 };
  private dimension = { chartTitle: 20, xAxis: 20, yAxis: 20, xTitle: 20, yTitle: 20, navChart: 70 };
  private barWidth = 3;
  private maxY = 100; 
  private minY = 0;
  private version = "0.0.1";
  private yTitle="";
  private xTitle="X Scale";
  private drawXAxis = true;
  private drawYAxis = true;
  private drawNavChart = true;
  private endTimeViewport;
  private startTimeViewport;
  private intervalViewport;
  private offsetViewport;

  private svg;
  private legendData;


  // define time scale
  private timeScale = d3.scaleLinear()
  .domain([300, 1700])
  .range([300, 1700])
  .clamp(true);
  
  // define value scale
  private valueScale = d3.scaleLinear()
  .domain([0, 1])
  .range([30, 95]);
  
  // generate initial data
  private normal = d3.randomNormal(10000, 150);
  private currMs = new Date().getTime() - 300000 - 4000;
  private data;
  private barId;
  private barG;
  private navG;
  private navArea;
  private navLine;
  private startTime;
  private endTime;
  private x; 
  private y;
  private interval;
  private xNav;
  private yNav;
  private viewport;
  private sel;
  private xAxis;
  private xAxisNav;
  private xAxisG;
  private xAxisGNav;
  private height;

  constructor(private websocketService: WebsocketService) { }

  ngOnInit(): void {
   this.data = this.chartData.times;
   this.legendData =  this.chartData.legends;
   if(this.data){
    this.drawTimelines();
    this.drawLegend();
    this.intervalData();
   }

   this.websocketService.dataChannel$
    .pipe(filter(item => (item => item instanceof MessageWs) && (item.collectionName === this.collectionName) && (this.isToday(this.today))))
    .subscribe(item => {
      console.log("MessageWs------->"+ item);
      this.data.push(item)
    });


    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      console.log('event: ', evt)
      d3.select(".legend").remove();
      d3.select("#chartDiv").remove();
      this.drawTimelines();
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    // Draw for the first time to initialize.  private svgWidth;
    console.log(changes);
   // this.generateData()
    if(changes.chartData !== undefined && !changes.chartData.firstChange){
      this.data= changes.chartData.currentValue.times;

      this.legendData =  changes.chartData.currentValue.legends;
      d3.select(".legend").remove();
      d3.select("#chartDiv").remove();
      this.drawTimelines();
      this.drawLegend();
      //this.intervalData();
    }
  }

ngOnDestroy() {
  if (this.interval) {
    clearInterval(this.interval);
  }
}

private isToday(date): boolean{
  const today = new Date();
  const someDate = new Date(date);
  const result= someDate.getDate() == today.getDate() &&
    someDate.getMonth() == today.getMonth() &&
    someDate.getFullYear() == today.getFullYear();
    return result;
}

private drawLegend(): void {
  var legendItemSize = 12;
    var legendSpacing = 4;
    var xOffset = 150;
    var yOffset = 100;
    var legend = this.svg.append('g')
                    .attr("transform", "translate (" + 0 + "," + 220 + ")")
                    .attr('class', 'legend')
                    .selectAll('.legendItem')
                    .data(this.legendData);
  
                        //Create legend items
      legend
      .enter()
      .append('rect')
      .attr('class', 'legendItem')
      .attr('width', legendItemSize)
      .attr('height', legendItemSize)
      .style('fill', d => d.color)
      .attr('transform',
                (d, i) => {
                    var x = xOffset  + (yOffset + legendSpacing) * i;
                    var y = yOffset;
                    return `translate(${x}, ${y})`;
                });
    
    //Create legend labels
    legend
      .enter()
      .append('text')
      .attr('x', (d, i) => xOffset + (yOffset + legendSpacing) * i + 12)
      .attr('y', yOffset + legendItemSize)
      .text(d => d.name);
}

private drawTimelines(): void {

    // process titles
    var chartTitle = this.chartTitle || "";
    var xTitle = this.xTitle || "";
    var yTitle = this.yTitle || "";

    //process data
    var initialData= this.data;

    // compute component dimensions
    var chartTitleDim = this.chartTitle == "" ? 0 : this.dimension.chartTitle;
    var xTitleDim = this.xTitle == "" ? 0 : this.dimension.xTitle;
    var yTitleDim = this.yTitle == "" ? 0 : this.dimension.yTitle;
    var xAxisDim = !this.drawXAxis ? 0 : this.dimension.xAxis;
    var yAxisDim = !this.drawYAxis ? 0 : this.dimension.yAxis;
    var navChartDim = !this.drawNavChart ? 0 : this.dimension.navChart;

    // compute chart dimension and offset
    var marginTop = this.margin.top + chartTitleDim;
    this.height = this.svgHeight - marginTop - this.margin.bottom - chartTitleDim - xTitleDim - xAxisDim - navChartDim + 30 - 60;
    var heightNav = navChartDim - this.margin.topNav - this.margin.bottomNav;
    var marginTopNav = this.svgHeight - this.margin.bottom - heightNav - this.margin.topNav - 60;
    var element = d3.select("#timeline").node();
    this.svgWidth = element.getBoundingClientRect().width - 100;

    var width = this.svgWidth - this.margin.left - this.margin.right;
    var widthNav = width;
    var border = this.border;

    // append the svg
    this.svg = d3.select("#viewDiv").append("div")
              .attr("id", "chartDiv")
              .append("svg")
              .attr("width", this.svgWidth)
              .attr("height", this.svgHeight)
              .style("border", (d)=>{ 
                if (border) return "0px solid lightgray"; 
                else return null;
              });

    // create main group and translate
    var main = this.svg.append("g")
        .attr("transform", "translate (" + this.margin.left + "," + marginTop + ")");

    // define clip-path
    main.append("defs").append("clipPath")
        .attr("id", "myClip")
      .append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", this.height);

    // create chart background
    main.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", this.height)
        .style("fill", "#ffffff");

    // note that two groups are created here, the latter assigned to barG;
    // the former will contain a clip path to constrain objects to the chart area; 
    // no equivalent clip path is created for the nav chart as the data itself
    // is clipped to the full time domain
    this.barG = main.append("g")
        .attr("class", "barGroup")
        .attr("transform", "translate(0, 0)")
        .attr("clip-path", "url(#myClip")
      .append("g");

    // add group for x axis
    this.xAxisG = main.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + this.height + ")");
    // add group for y axis
    var yAxisG = main.append("g")
        .attr("class", "y axis");

    // in x axis group, add x axis title
    this.xAxisG.append("text")
        .attr("class", "title")
        .attr("x", width / 2)
        .attr("y", 25)
        .attr("dy", ".71em")
        .text((d)=>{ 
          var text = this.xTitle == undefined ? "" : this.xTitle;
          return text; 
        });

    // in y axis group, add y axis title
    yAxisG.append("text")
        .attr("class", "title")
        .attr("transform", "rotate(-90)")
        .attr("x", - this.height / 2)
        .attr("y", -35)
        .attr("dy", ".71em")
        .text((d)=>{ 
          var text = this.yTitle == undefined ? "" : this.yTitle;
          return text; 
        });

    // in main group, add chart title
    main.append("text")
        .attr("class", "chartTitle")
        .attr("x", width / 2)
        .attr("y", -20)
        .attr("dy", ".71em")
        .text((d)=>{ 
          var text = this.chartTitle;
          return text; 
        });

    // define main chart scales
    this.x = d3.scaleTime().range([0, width]);
    this.y = d3.scaleLinear().domain([this.minY, this.maxY]).range([this.height, 0]);
    // define main chart axis
    this.xAxis = d3.axisBottom(this.x);

    // add nav chart
    var nav = this.svg.append("g")
        .attr("transform", "translate (" + this.margin.left + "," + marginTopNav + ")");

    // add nav background
    nav.append("rect")
        .attr("x", 0)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", heightNav)
        .style("fill", "#ffffff")
        .style("shape-rendering", "crispEdges")
        .attr("transform", "translate(0, 0)");

    // add group to hold line and area paths
    this.navG = nav.append("g")
        .attr("class", "nav");

    // add group to hold nav x axis
    var xAxisGNav = nav.append("g")
        .attr("class", "x axis")
        .attr("transform", "translate(0," + heightNav + ")");

    this.xAxisGNav = xAxisGNav;
    // define nav scales
    this.xNav = d3.scaleTime().range([0, widthNav]);
    this.yNav = d3.scaleLinear().domain([this.minY, this.maxY]).range([heightNav, 0]);
    // define nav axis
    this.xAxisNav = d3.axisBottom(this.xNav);

    // define function that will draw the nav area chart
    this.navArea = d3.area()
        .x((d: any)=>{ return this.xNav(new Date(d.time)); })
        .y1((d: any)=>{ return this.yNav(d.value); })
        .y0(heightNav);

    // define function that will draw the nav line chart
    this.navLine = d3.line()
        .x((d: any)=>{ return this.xNav(new Date(d.time)); })
        .y((d: any)=>{ return this.yNav(d.value); });

        var ts, dt;
     // compute initial time domains...
     if(this.isToday(this.today)){
      var date= new Date();
      ts = date.getTime();
      dt = new Date();
      dt.setHours(0,0,0,0);
     }else{
       dt = new Date(this.today);
       dt.setHours(0, 0, 0, 0);
       var endOfDay = new Date(this.today);  
       endOfDay.setHours(23, 59, 59, 0);
       ts =endOfDay.getTime();
     }
     var resultInSeconds=(ts - dt.getTime());

    // first, the full time domain
    this.endTime = new Date(ts);
    this.startTime = new Date(this.endTime.getTime() - resultInSeconds);
    var interval = this.endTime.getTime() - this.startTime.getTime();

    // then the viewport time domain (what's visible in the main chart 
    // and the viewport in the nav chart)
    this.endTimeViewport = new Date(ts);
    this.startTimeViewport = new Date(this.endTime.getTime() - width / this.pixelsPerSecond * 1000);
    this.intervalViewport = this.endTimeViewport.getTime() - this.startTimeViewport.getTime();
    this.offsetViewport = this.startTimeViewport.getTime() - this.startTime.getTime();

    // set the scale domains for main and nav charts
    this.x.domain([this.startTimeViewport, this.endTimeViewport]);
    this.xNav.domain([this.startTime, this.endTime]); 

    // update axis with modified scale
    this.xAxis.scale(this.x)(this.xAxisG);
    //yAxis.scale(y)(yAxisG);
    this.xAxisNav.scale(this.xNav)(xAxisGNav);
    this.xNav = this.xNav;

    // create brush (moveable, changable rectangle that determines 
    // the time domain of main chart)
    this.viewport = d3.brushX()
    .extent([ [0,0], [widthNav, heightNav] ])
    .on("brush end", ()=>{
      // get the current time extent of viewport
      var extent = d3.event.selection || this.xNav.range();
    
      // console.log(extent);
      this.startTimeViewport = this.xNav.invert(extent[0]);
      this.endTimeViewport = this.xNav.invert(extent[1]);
    
      this.intervalViewport = this.endTimeViewport.getTime() - this.startTimeViewport.getTime();
      this.offsetViewport = this.startTimeViewport.getTime() - this.startTime.getTime();
    
      // handle invisible viewport
      if (this.intervalViewport == 0) {

        var dt;
        // compute initial time domains...
       if(this.isToday(this.today)){
        this.endTime = new Date();
        dt = new Date();
        dt.setHours(0,0,0,0);
        }else{
          this.endTime = new Date(this.today);
          this.endTime.setHours(23, 59, 59, 0);
          dt = new Date(this.today);
          dt.setHours(0, 0, 0, 0);
        }
        var resultInSeconds=(this.endTime.getTime() - dt.getTime());
        this.intervalViewport = resultInSeconds;
        this.offsetViewport = 0;
      }
    
      // update the x domain of the main chart
      this.x.domain((extent === null) ? this.xNav.domain() : extent);
    
      // update the x axis of the main chart
      this.xAxis.scale(this.x)(this.xAxisG);/**/
    
      this.sel = d3.event.selection || this.xNav.range();
      this.x.domain(this.sel.map(this.xNav.invert, this.xNav));
      // update display
      this.refresh();
    });

    // create group and assign to brush
    var viewportG = nav.append("g")
    .attr("class", "viewport")
    .call(this.viewport)
    .selectAll("rect")
    .attr("height", heightNav);


    // initial invocation
    this.data = initialData || [];

    // update display
      this.refresh();
}

private intervalData () {

    this.interval = setInterval(() => {


    // get current viewport extent
    // var extent = viewport.empty() ? xNav.domain() : viewport.extent();
    // var extent = (sel === null) ? xNav.domain() : viewport.extent();
    var extent = this.xNav.domain();
          var interval = extent[1].getTime() - extent[0].getTime();
    var offset = extent[0].getTime() - this.xNav.domain()[0].getTime();
    // var interval = extent[1].getTime() - extent[0].getTime();
    // var offset = extent[0].getTime() - xNav.domain()[0].getTime();
    // compute new nav extents
    // this.endTime = new Date();
    // this.startTime = new Date(this.endTime.getTime() - this.maxSeconds * 1000);

    var dt;
    // compute initial time domains...
   if(this.isToday(this.today)){
    this.endTime = new Date();
     dt = new Date();
    dt.setHours(0,0,0,0);
    }else{
      this.endTime = new Date(this.today);
      this.endTime.setHours(23, 59, 59, 0);
      dt = new Date(this.today);
      dt.setHours(0, 0, 0, 0);
    }

    var resultInSeconds=(this.endTime.getTime() - dt.getTime());
    this.startTime = new Date(this.endTime.getTime() - resultInSeconds);

    // compute new viewport extents 
    this.startTimeViewport = new Date(this.startTime.getTime() + offset);
    this.endTimeViewport = new Date(this.startTimeViewport.getTime() + interval);
    this.viewport.extent([[40,this.startTimeViewport.getTime()], [40, this.endTimeViewport.getTime()]])

    // update scales

    this.x.domain([this.startTimeViewport, this.endTimeViewport]);
    if(this.sel) {
      this.x.domain(this.sel.map(this.xNav.invert, this.xNav));
    }
    this.xNav.domain([this.startTime, this.endTime]);

    // update axis
    this.xAxis.scale(this.x)(this.xAxisG);
    this.xAxisNav.scale(this.xNav)(this.xAxisGNav);

    // refresh svg
     this.refresh();

     if(!this.isToday(this.today)){
      clearInterval(interval);
      return;
  }
  },200)

}

// function to refresh the viz upon changes of the time domain 
// (which happens constantly), or after arrival of new data,
// or at init
private refresh(): void {
  var barId = this.barId;
  var startTime = this.startTime;
  var endTime = this.endTime;
  var height = this.height ;
  var x = this.x;
  var y = this.y;


 // process data to remove too late or too early data items 
  // (the latter could occur if the chart is stopped, while data
  // is being pumped in)
  this.data = this.data.filter((d)=>{
    var date = new Date(d.time);
   date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  if (date.getTime() > startTime.getTime() &&
  date.getTime() < endTime.getTime()) 
    return true;
})
 
  // here we bind the new data to the main chart
  // note: no key function is used here; therefore the data binding is
  // by index, which effectivly means that available DOM elements
  // are associated with each item in the available data array, from 
  // first to last index; if the new data array contains fewer elements
  // than the existing DOM elements, the LAST DOM elements are removed;
  // basically, for each step, the data items "walks" leftward (each data 
  // item occupying the next DOM element to the left);
  // This data binding is very different from one that is done with a key 
  // function; in such a case, a data item stays "resident" in the DOM
  // element, and such DOM element (with data) would be moved left, until
  // the x position is to the left of the chart, where the item would be 
  // exited
  var updateSel = this.barG.selectAll(".bar")
      .data(this.data);
 
  // remove items
  updateSel.exit().remove();
 
  // append items
  updateSel.enter().append("rect")
      .attr("class", "bar")
      .attr("id", function() { 
        return "bar-" + barId++; 
      })
      .attr("shape-rendering", "crispEdges");
  this.barId++;
  // update items
  updateSel
      .attr("x", (d)=> { 
        // console.log(x(d.time));
        var val = Math.round(this.x(new Date(d.time)) - this.barWidth);
        if(val !== val) {
          val = 0;
        }
        return val; })
    
      .attr("y", (d)=>{ return this.y(d.value); })
      .attr("width", (d)=>{ return d.range })
      .attr("height", (d)=>{ return height - this.y(d.value); })
      .style("fill", (d)=>{ return d.color == undefined ? "#ffffff" : d.color; })
      //.style("stroke", "none")
      //.style("stroke-width", "1px")
      //.style("stroke-opacity", 0.5)
      .style("fill-opacity", 1);
 
  // also, bind data to nav chart
  // first remove current paths
  this.navG.selectAll("path").remove();
 
  // then append area path...
  this.navG.append('path')
      .datum(this.data)
      .attr('class', 'area')
      .attr('d', this.navArea)
      .style("fill", "#bcaaa4");
  // ...and line path
  this.navG.append('path')
      .datum(this.data)
      .attr('class', 'line')
      .attr('d', this.navLine)
      .style("fill",  "red");
 
 } // end refreshChart function


}