import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

export interface AppConfiguration {
  production: boolean;
  apiEndpoint: string;
}

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  constructor() {}

  getConfig(key: keyof AppConfiguration) {
    if (!environment) {
      throw new Error('No configuration, something went wrong');
    }
    console.log(environment);
    return environment[key];
  }
}
