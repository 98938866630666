import { Component, Input, OnInit, VERSION } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { ActivatedRoute, Params } from '@angular/router';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DatePipe } from '@angular/common';
import { TimelineService } from '../shared/timeline/timeline.service';
import { FormControl } from '@angular/forms';
import { WebsocketService } from '../core/services/websocket.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { InstantValue } from '../shared/timeline/timeline.model';
import { HistogramService } from '../shared/histogram/histogram.service';
import { CompteurInstantValue, SimpleDataModel } from '../shared/histogram/histogram.model';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit{
  @Input() max: any;
  today = new Date();
  bigChart = [];
  cards = [];
  public piechart;
  public collectionName: string;
  public chartData;
  public timeline;
  public chartTitle;
  subscription?: Subscription;
  private dateFormat = 'yyyy-MM-dd';
  public fromDate: any;
  public toDate: any;
  public firstDate: any;
  public instantValue$: BehaviorSubject<InstantValue> = new BehaviorSubject<InstantValue>(null);
  public compteurInstantValue$: BehaviorSubject<CompteurInstantValue> = new BehaviorSubject<CompteurInstantValue>(null);
  public seuilMin;
  public seuilMax;
  public isDate: boolean;
  public isChrono: boolean;
  public isCompteur: boolean;
  public times= [];
  public dataHistograms: SimpleDataModel[];

  public colorDay;
  public colorPreviousDay;
  public labelDay;
  public labelPreviousDay;
  public informationDesc;

  /** Based on the screen size, switch from standard to one column per row */
  chartCards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({ matches }) => {
      if (matches) {
        return [
          { title: 'Card 1', cols: 1, rows: 1 },
          { title: 'Card 2', cols: 1, rows: 1 },
          { title: 'Card 3', cols: 1, rows: 1 },
          { title: 'Card 4', cols: 1, rows: 1 }
        ];
      }

      return [
        { chart: 'statistic', cols: 3, rows: 1 },
        { chart: 'compteur', cols: 3, rows: 1 },
        { chart: 'test2', cols: 3, rows: 1 },
        { chart: 'pie', cols: 3, rows: 1 },
      ];
    })
  );

  constructor(private breakpointObserver: BreakpointObserver,
    private activatedRoute: ActivatedRoute,
    private timelineService: TimelineService,
    private histogramService: HistogramService,
    private websocketService: WebsocketService,
    private datePipe: DatePipe) {
      console.log('constructor dashboard');
      
      this.fromDate = new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      this.toDate = new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      this.firstDate = new FormControl(this.datePipe.transform(new Date(), 'yyyy-MM-dd'));
      this.isDate = this.isToday(new Date(this.fromDate.value));
      console.log('dates dashboard');
    }

  ngOnInit(): void {

    this.activatedRoute.params.subscribe((param: Params) => {
      this.collectionName = param['id'];
      console.log('collectionName : ', this.collectionName);

      var typeAffichage = this.collectionName.split("-", 1); 
      if(typeAffichage[0]=== 'chronogramme'){
        this.isChrono = true;
        this.isCompteur = false;
        this.loadTimelines(this.collectionName);
      }else if(typeAffichage[0]=== 'compteur'){
        this.isChrono = false;
        this.isCompteur = true;
        this.loadHistogram(this.firstDate.value);
      }

    });

    this.websocketService.dataChannel$
    .pipe(filter(item => (item => item instanceof InstantValue) && (item.collectionName === this.collectionName) && (this.isToday(new Date(this.fromDate.value)))))
     .subscribe(item => {
        console.log(item);
        this.instantValue$.next(item);
     });

     this.websocketService.dataChannel$
     .pipe(filter(item => (item => item instanceof CompteurInstantValue) && (item.collectionName === this.collectionName) && (this.isToday(new Date(this.firstDate.value)))))
      .subscribe(item => {
         console.log(item);
         this.loadHistogram(this.firstDate.value);
      });
  }

  loadTimelines(collectionName) {
    this.timelineService
      .getTimelines({ collectionName: collectionName })
      .subscribe(
        (data) => {
          this.chartData = data;
          this.piechart = this.chartData.pie;
          this.timeline = this.chartData.timeline;
          this.times = this.chartData.timeline.times;
          this.instantValue$.next({ ...this.instantValue$.value,
            horodatage: this.chartData.statistic.horodatage,
            value: this.chartData.statistic.value,
            unity: this.chartData.statistic.unity
          });
          //this.dashboardService.initialize(this.chartData.times);
        },
        (err) => console.error(err)
      );
  }

  loadHistograms(collectionName) {
    this.histogramService.findHistogramByCollectionName({ collectionName: collectionName })
      .subscribe(
        (data) => {
          this.dataHistograms = data.histogrammes;
          this.colorDay = data.colorDay;
          this.labelDay = data.labelDay;
          this.colorPreviousDay = data.colorPreviousDay;
          this.labelPreviousDay = data.labelPreviousDay;
          this.informationDesc = data.informationDesc;
          this.compteurInstantValue$.next({ ...this.compteurInstantValue$.value,
            compteur: data.compteur
          });
        },
        (err) => console.error(err)
      );
  }

  load(fromDate: string, toDate: string) {
    this.activatedRoute.params.subscribe((param) => {
      this.collectionName = param['id'];
      console.log('collectionName : ', this.collectionName);
      this.isDate = this.isToday(new Date(fromDate));
      this.timelineService
        .findByDates({
          collectionName: this.collectionName,
          fromDate: fromDate,
          toDate: toDate,
        })
        .subscribe(
          (data) => {
            this.chartData = data;
            this.piechart = this.chartData.pie;
            this.timeline = this.chartData.timeline;
            this.times = this.chartData.timeline.times;
            this.instantValue$.next({ ...this.instantValue$.value,
              horodatage: this.chartData.statistic.horodatage,
              value: this.chartData.statistic.value,
              unity: this.chartData.statistic.unity
            });
          },
          (err) => console.error(err)
        );
    });
  }

  transition(type: string, event: MatDatepickerInputEvent<Date>) {
    let fromDate;
    let toDate;
    toDate = this.datePipe.transform(event.value, this.dateFormat);
    fromDate = this.datePipe.transform(event.value, this.dateFormat);
    this.fromDate = new FormControl(toDate);
    this.toDate = new FormControl(fromDate);
    console.log('fromDate : ', fromDate);
    console.log('toDate : ', toDate);
    this.load(fromDate, toDate);
  }


  transitionHisto(type: string, event: MatDatepickerInputEvent<Date>) {
    let date = this.datePipe.transform(event.value, this.dateFormat);
    this.firstDate = new FormControl(date);
    console.log('firstDate : ', date);
    this.loadHistogram(date);
  }

  loadHistogram(date: string) {
    this.activatedRoute.params.subscribe((param) => {
      this.collectionName = param['id'];
      console.log('collectionName : ', this.collectionName);

      // this.dataHistograms = this.dataTest.histogrammes;
      // this.colorDay = this.dataTest.colorDay;
      // this.labelDay = this.dataTest.labelDay;
      // this.colorPreviousDay = this.dataTest.colorPreviousDay;
      // this.labelPreviousDay = this.dataTest.labelPreviousDay;
      // this.informationDesc = this.dataTest.informationDesc;

      this.histogramService.findHistogramByCollectionNameAndDate({
        collectionName: this.collectionName,
        date: date
      })
      .subscribe(
        (data) => {
          this.dataHistograms = data.histogrammes;
          this.colorDay = data.colorDay;
          this.labelDay = data.labelDay;
          this.colorPreviousDay = data.colorPreviousDay;
          this.labelPreviousDay = data.labelPreviousDay;
          this.informationDesc = data.informationDesc;
          this.compteurInstantValue$.next({ ...this.compteurInstantValue$.value,
            compteur: data.compteur
          });
        },
        (err) => console.error(err)
      );
    });
  }

  private isToday(someDate): boolean{
    const today = new Date();
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }
}