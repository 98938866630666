<mat-toolbar color="primary">
  <mat-toolbar-row>
    <button mat-icon-button (click)="toggleSideBar()">
      <mat-icon>menu</mat-icon>
    </button>
    <button
      mat-button
      routerLink=""
      [routerLinkActiveOptions]="{ exact: true }"
      routerLinkActive="active-link"
    >
      <input type="image" id="image" alt="MeerkatSpirit" width="80"  src="/assets/logo.png">
    </button>

    <div fxFlex fxLayout="row" fxLayoutAlign="flex-end">
      <ul fxLayout="row" fxLayoutGap="20px">
        <li>
          <!-- <button mat-icon-button>
            <mat-icon>notifications_active</mat-icon>
          </button> -->
          <button mat-button (click)="openNewTab()">
            Lien Admin
          </button>
        </li>
        
        <li *ngIf="userInfoOidc$ | async">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon>person_outline</mat-icon>
            <mat-icon>expand_more</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <mat-list role="list">
              <mat-list-item role="listitem">{{
                (userInfoOidc$ | async).name
              }}</mat-list-item>
              <mat-list-item role="listitem">{{
                (userInfoOidc$ | async).email
              }}</mat-list-item>
            </mat-list>
            <button mat-menu-item (click)="signOut()">
              <mat-icon>exit_to_app</mat-icon>
              Sign out
            </button>
          </mat-menu>
        </li>
      </ul>
    </div>
  </mat-toolbar-row>
</mat-toolbar>
