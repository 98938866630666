import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';
import { UserInfoService } from './user-info.service';
import { UserInfo } from './user-info';

@Injectable()
export class UserInfoStore {
  private _userInfo: ReplaySubject<UserInfo> = new ReplaySubject(1);
  public userInfoLoaded = false;

  private userId: string;

  constructor(private userInfoService: UserInfoService) {}

  private loadInitialData() {
    this.userInfoService.getUserInfo().subscribe((data) => {
      this.userId = data.sub;
      this._userInfo.next(data);
    });
  }

  public getUserInfo$(): Observable<UserInfo> {
    
    if (!this.userInfoLoaded) {
      this.loadInitialData();
      this.userInfoLoaded = true;
    }
    return this._userInfo.asObservable();
  }
}
