<div class="grid-container" *ngIf="isChrono && instantValue$ | async as instantValue">
  <div id="kpi-wrap">
    <mat-grid-list [cols]="12" rowHeight="131px">
      <mat-grid-tile [colspan]="6" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">
            <mat-form-field>
              <mat-label>date</mat-label>
              <input
                matInput
                [matDatepicker]="picker1"
                [formControl]="fromDate"
                [max]="today"
                (dateChange)="transition('fromDate', $event)"
              />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            &nbsp;&nbsp;&nbsp;
            <mat-form-field *ngIf="false">
              <mat-label>end</mat-label>
              <input
                matInput
                [matDatepicker]="picker2"
                [formControl]="toDate"
                [max]="today"
                (dateChange)="transition('toDate', $event)"
              />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile  [colspan]="3" [rowspan]="1" *ngIf="isDate">
        <mat-card class="dashboard-card">
          <mat-card-header class="dashboard-card__header">
            <mat-card-title>
              <h3 class="instant-title">HEURE EVENEMENT</h3>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
            <p class="instant-result">{{ instantValue.horodatage }}</p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile  [colspan]="3" [rowspan]="1" *ngIf="isDate">
        <mat-card class="dashboard-card">
          <mat-card-header class="dashboard-card__header">
            <mat-card-title>
              <h3 class="instant-title">VALEUR INSTANTANEE</h3>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
            <p class="instant-result">{{ instantValue.value }} {{instantValue.unity}}</p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div id="info-desc">
    <mat-grid-list [cols]="12" rowHeight="131px">
      <mat-grid-tile  [colspan]="12" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-header class="dashboard-card__header">
            <mat-card-title>
              <h3 class="instant-title">DESCRIPTION</h3>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="times?.length > 0">{{ times[0].informationDesc }}</p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div>
    <mat-grid-list cols="12" rowHeight="600px" id="timeline">
      <mat-grid-tile [colspan]="12" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">              
            <timeline [chartTitle]="chartTitle"
              [collectionName]="collectionName"
              [chartData]="timeline"
              [today]="fromDate.value"></timeline> 
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list> 
  </div>
  <div>
    <mat-grid-list cols="12" rowHeight="600px">
      <mat-grid-tile *ngFor="let card of chartCards | async" [colspan]="card.cols" [rowspan]="card.rows">
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">
            <div>
              <app-pie [piechart]="piechart" [today]="fromDate.value" *ngIf="piechart && card.chart === 'compteur'"></app-pie>
            </div>
            <!--<div>
              <app-histogram [title]="'Framework'" [barData]="dataTest"></app-histogram>
               <app-d3-pie [enablePolylines]="true" [isPercentage]="true" [pieData]="data"></app-d3-pie> 
              <app-pie [piechart]="piechart" [today]="fromDate.value" *ngIf="piechart && card.chart === 'pie'"></app-pie> 
            </div>-->

            
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<div class="grid-container" *ngIf="isCompteur && dataHistograms">
  <div id="info-desc">
    <mat-grid-list [cols]="12" rowHeight="131px">
      <mat-grid-tile [colspan]="9" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">
            <mat-form-field>
              <mat-label>date</mat-label>
              <input
                matInput
                [matDatepicker]="pickerHisto"
                [formControl]="firstDate"
                (dateChange)="transitionHisto('dataHistograms', $event)"
              />
              <mat-datepicker-toggle matSuffix [for]="pickerHisto"></mat-datepicker-toggle>
              <mat-datepicker #pickerHisto></mat-datepicker>
            </mat-form-field>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile  [colspan]="3" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-header class="dashboard-card__header">
            <mat-card-title>
              <h3 class="instant-title">COMPTEUR TOTAL</h3>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content class="dashboard-card-content">
            <p class="instant-result" *ngIf="compteurInstantValue$ | async as compteurInstantValue">{{ compteurInstantValue.compteur }}</p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
      <mat-grid-tile  [colspan]="12" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-header class="dashboard-card__header">
            <mat-card-title>
              <h3 class="instant-title">DESCRIPTION</h3>
            </mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <p *ngIf="dataHistograms?.length > 0">{{ informationDesc }}</p>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  <div>
    <mat-grid-list cols="12" rowHeight="600px" id="timeline">
      <mat-grid-tile [colspan]="12" [rowspan]="1">
        <mat-card class="dashboard-card">
          <mat-card-content class="dashboard-card-content">              
              <div #elseCompteur >
                <app-histogram [title]="'Framework'" [barData]="dataHistograms" [colorDay]="colorDay" [labelDay]="labelDay" [colorPreviousDay]="colorPreviousDay" [labelPreviousDay]="labelPreviousDay"></app-histogram>
              </div>
          </mat-card-content>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list> 
  </div>
</div>