<mat-nav-list>
  <!-- <div class="profile-card">
        <div class="header">
            <h2>User</h2>
            <h4>example@meerkat-spirit.com</h4>
        </div>
        <img src="https://images.unsplash.com/photo-1558369388-c30b19a93676?ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60" alt="User">
    </div>

    <mat-divider></mat-divider>

    <h2 matSubheader>Links</h2>

    <a mat-list-item routerLinkActive="list-item-active" routerLink="/navigation">Navigation</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/posts">Posts</a>
    <a mat-list-item routerLinkActive="list-item-active" routerLink="/notifications">Notifications</a> -->

  <!-- <gy-tree-view></gy-tree-view> -->

  <app-tree-with-tooltips></app-tree-with-tooltips>
</mat-nav-list>
