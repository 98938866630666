import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatListModule } from '@angular/material/list';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTreeModule } from '@angular/material/tree';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouterModule } from '@angular/router';
import { MatCardModule } from '@angular/material/card';
import { AreaComponent } from './widgets/area/area.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { CardComponent } from './widgets/card/card.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { HeaderComponent } from './template/header/header.component';
import { FooterComponent } from './template/footer/footer.component';
import { SidebarComponent } from './template/sidebar/sidebar.component';
import { HttpClientModule } from '@angular/common/http';
import { GyTreeViewComponent } from './gy-tree-view/gy-tree-view.component';
import { MatNativeDateModule } from '@angular/material/core';
import { TimelineComponent } from './timeline/timeline.component';
import { TimelineService } from './timeline/timeline.service';
import { PieComponent } from './pie/pie.component';
import { D3PieComponent } from './d3-pie/d3-pie.component';
import { HistogramComponent } from './histogram/histogram.component';
import { HistogramService } from './histogram/histogram.service';
import { TreeWithTooltipsComponent } from './tree-with-tooltips/tree-with-tooltips.component';
import { TruncatePipe } from './tree-with-tooltips/truncate.pipe';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AreaComponent,
    CardComponent,
    PieComponent,
    D3PieComponent,
    HistogramComponent,
    TimelineComponent,
    GyTreeViewComponent,
    TreeWithTooltipsComponent,
    TruncatePipe,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    MatToolbarModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    FlexLayoutModule,
    MatMenuModule,
    MatTreeModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    RouterModule,
    HighchartsChartModule,
    MatTooltipModule,
  ],
  providers: [TimelineService, HistogramService],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    AreaComponent,
    CardComponent,
    PieComponent,
    D3PieComponent,
    HistogramComponent,
    HttpClientModule,
    TimelineComponent,
    GyTreeViewComponent,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
  ],
})
export class SharedModule {}
