import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { HttpClient } from '@angular/common/http';
import { shareReplay } from 'rxjs/operators';
import { UserInfo } from './user-info';
import { environment } from 'src/environments/environment';

@Injectable()
export class UserInfoService {
  private usersInfoEndpoint = ''; // URL to web api

  constructor(private httpClient: HttpClient) {
    this.usersInfoEndpoint =
      environment.keycloak.url +
      '/realms/' +
      environment.keycloak.realm +
      '/protocol/openid-connect/userinfo';
  }

  getUserInfo(): Observable<UserInfo> {
    return this.httpClient
      .get<UserInfo>(this.usersInfoEndpoint)
      .pipe(shareReplay());
  }
}
