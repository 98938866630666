import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigService } from './services/config.service';
import { UserInfoService } from './user/user-info.service';
import { UserInfoStore } from './user/user-info.store';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [ConfigService, UserInfoStore, UserInfoService],
})
export class CoreModule {}
