import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { ConfigService } from 'src/app/core/services/config.service';
import { InfosHistoDataModel, SimpleDataModel } from './histogram.model';
import { Observable } from 'rxjs';

@Injectable()
export class HistogramService {
  private apiUrl = 'api/histogrammes';

  constructor(private http: HttpClient, private configService: ConfigService) {
    this.apiUrl = this.configService.getConfig('apiEndpoint') + this.apiUrl;
  }

  findHistogramByCollectionName(req: any): Observable<InfosHistoDataModel> {
    let url = `${this.apiUrl}?collectionName=${req.collectionName}`;
    return this.http.get<InfosHistoDataModel>(url);

  }

  findHistogramByCollectionNameAndDate(req: any): Observable<InfosHistoDataModel> {
    let url = `${this.apiUrl}?collectionName=${req.collectionName}&andDate=${req.date}`;
    return this.http.get<InfosHistoDataModel>(url);

  }
}
