import {
  AfterViewInit,
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges
} from "@angular/core";
import { D3Service } from "../d3-pie/d3-pie.service";
import { SimpleDataModel } from "./histogram.model";
import { fromEvent, Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-histogram',
  templateUrl: './histogram.component.html',
  styleUrls: ['./histogram.component.scss']
})
export class HistogramComponent implements OnInit, AfterViewInit, OnChanges {
  resizeObservable$: Observable<Event>;
  resizeSubscription$: Subscription;
  @Input("barData") private data: SimpleDataModel[];
  @Input("colorDay") public colorDay;
  @Input("colorPreviousDay") public colorPreviousDay;
  @Input("labelDay") public labelDay;
  @Input("labelPreviousDay") public labelPreviousDay;
  public chartId;
  private highestValue: string;
  private svg;
  private margin = { top: 20, bottom: 20, left: 50, right: 30, topNav: 10, bottomNav: 20 };

  private width;
  private widthDrawing;
  private height = 500;
  private heightDrawing = this.height - this.margin.bottom -this.margin.bottomNav;
  constructor(private d3: D3Service) {
    this.chartId = this.d3.generateId(5);
  }

  ngOnInit(): void {
    // determining highest value
    let highestCurrentValue = 0;
    let tableLength = this.data.length;
    this.data.forEach((data, i) => {
      const barValue = Number(data.value);
      if (barValue > highestCurrentValue) {
        highestCurrentValue = barValue;
      }
      if (tableLength == i + 1) {
        this.highestValue = highestCurrentValue.toString();
      }
    });

    this.resizeObservable$ = fromEvent(window, 'resize')
    this.resizeSubscription$ = this.resizeObservable$.subscribe( evt => {
      console.log('event: ', evt)
      this.d3.d3.select("div#chart svg").remove();
      this.processHistogram();
    })
 }


  ngAfterViewInit(): void {
    this.processHistogram();
  }

  ngOnChanges(changes: SimpleChanges) {
    // Draw for the first time to initialize.  private svgWidth;
    console.log(changes);
    this.d3.d3.select("div#chart svg").remove();
    this.processHistogram();
  }

  private processHistogram(): void {
    var element = this.d3.d3.select("div#chart").node();
    var svgWidth = element.getBoundingClientRect().width;
    if(svgWidth !== 0){
      this.createSvg(svgWidth);
      this.drawBars(this.data);
      this.createLegend();
    }
 }
  private createSvg(svgWidth: any): void {
      this.width = svgWidth;
      this.widthDrawing = svgWidth - this.margin.right - 20;
      this.svg = this.d3.d3
      .select("div#chart")
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
        .append("g")
        .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

  }

  private drawBars(data: any[]): void {
    // Creating X-axis band scale
    const x = this.d3.d3
      .scaleBand()
      .range([0, this.widthDrawing])
      .domain(data.map(d => d.time))
      .padding(0.1);

    // Drawing X-axis on the DOM
    this.svg
      .append("g")
      .attr("transform", "translate(0," + this.heightDrawing + ")")
      .call(this.d3.d3.axisBottom(x))
      .selectAll("text")
      // .attr('transform', 'translate(-10, 0)rotate(-45)')
      // .style('text-anchor', 'end')
      .style("font-size", "14px");

    // Creaate Y-axis band scale
    const y = this.d3.d3
      .scaleLinear()
      .domain([0, Number(this.highestValue) + 50])
      .range([this.heightDrawing, 0]);

    // Draw the Y-axis on the DOM
    this.svg
      .append("g")
      .call(this.d3.d3.axisLeft(y))
      .selectAll("text")
      .style("font-size", "14px");

      const dmax = this.d3.d3.max(data);

    // Create and fill the bars
    this.svg
      .selectAll("bars")
      .data(data)
      .enter()
      .append("rect")
      .attr("x", d => x(d.time))
      .attr("y", d => y(d.value))
      .attr("width", x.bandwidth())
      .attr("height", d =>(this.heightDrawing - y(d.value))) // this.height
      .attr("fill", d => d.color);

    this.svg
      .selectAll("text.bar")
      .data(data)
      .enter()
      .append("text")
      .attr("text-anchor", "middle")
      .attr("fill", "#70747a")
      .attr("x", d => x(d.time) + 18)
      .attr("y", d => y(d.value) - 5)
      .text(d => Math.round(d.value * 100) / 100);
  }

  private createLegend(): void {
    // Handmade legend
    this.svg.append("circle").attr("cx",300).attr("cy",60).attr("r", 6).style("fill", this.colorDay)
    this.svg.append("text").attr("x", 320).attr("y", 60).text(this.labelDay).style("font-size", "15px").attr("alignment-baseline","middle")
    if(this.colorPreviousDay)
      this.svg.append("circle").attr("cx",300).attr("cy",30).attr("r", 6).style("fill", this.colorPreviousDay)
    if(this.labelPreviousDay)
      this.svg.append("text").attr("x", 320).attr("y", 30).text(this.labelPreviousDay).style("font-size", "15px").attr("alignment-baseline","middle")
  }
}
